<template>
  <div>
    <div class="align-center text-center mt-6 mx-6 mb-10">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.devicesData") }}</v-toolbar-title>
        </v-toolbar>
        <div class="pl-4 pr-4 pt-2 pb-2">
          <v-card>

            <v-data-table
              v-if="
                $i18n.locale == 'en' &&
                userServices.cards8 == true &&
                userServices.cards12 == false
              "
              :headers="headers"
              :search="search"
              :items="payload"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
              :loading="this.myloadingvariable"
              loading-text="Loading..."
            >
            </v-data-table>

            <v-data-table
              v-if="
                $i18n.locale == 'pt' &&
                userServices.cards8 == true &&
                userServices.cards12 == false
              "
              :headers="headersPt"
              :search="search"
              :items="payload"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
              :loading="this.myloadingvariable"
              loading-text="Loading..."
            >
            </v-data-table>

            <v-data-table
              v-if="$i18n.locale == 'en' && userServices.cards12 == true"
              :headers="headers12"
              :search="search"
              :items="payload"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
              :loading="this.myloadingvariable"
              loading-text="Loading..."
            >
            </v-data-table>

            <v-data-table
              v-if="$i18n.locale == 'pt' && userServices.cards12 == true"
              :headers="headersPt12"
              :search="search"
              :items="payload"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
              :loading="this.myloadingvariable"
              loading-text="Loading..."
            >
            </v-data-table>
          </v-card>
        </div>

        <div>
          <v-row class="ma-4" v-if="(userServices.advPortal && userServices.feeBasic) || userServices.csvExport">
            <!-- DATE PICKER -->
            <v-col cols="3">
              <v-card
                v-if="userServices.feeBasic"
                outlined
                style="
                  border-width: 1px;
                  border-color: #757575;
                  max-width: 250px;
                  max-height: 55px;
                "
              >
                <v-col cols="12" sm="11" md="11" xl="11" class="pa-0">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dateRange"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRange"
                        :label="$t('button.dateRange')"
                        persistent-hint
                        prepend-icon="date_range"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateRange"
                      range
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu.save(dateRange);
                          passDateRangeToDataTable();
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-card>
            </v-col>

            <v-col cols="3">
              <v-select
                v-if="$i18n.locale == 'en' && userServices.feeBasic"
                class="d-flex justify-center"
                style="max-width: 200px; max-height: 30px"
                :items="periodsToSelect"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
              <v-select
                v-if="$i18n.locale == 'pt' && userServices.feeBasic"
                class="d-flex justify-center"
                style="max-width: 200px; max-height: 30px"
                :items="periodsToSelectPt"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
              <v-select
                v-if="userServices.csvExport && $i18n.locale == 'en' && !userServices.feeBasic"
                class="d-flex justify-center"
                style="max-width: 200px; max-height: 30px"
                :items="periodsToSelect"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
              <v-select
                v-if="userServices.csvExport && $i18n.locale == 'pt' && !userServices.feeBasic"
                class="d-flex justify-center"
                style="max-width: 200px; max-height: 30px"
                :items="periodsToSelectPt"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
            </v-col>

            <!--SEARCH BAR-->
            <v-col cols="6" class="mt-0 pt-0">
              <v-card-title class="">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>

          <!-- <v-row class="mb-4 mr-4" v-if="userServices.limitedPortal"> -->
            <!--SEARCH BAR-->
            <!-- <v-col cols="12">
              <v-spacer></v-spacer>
              <v-card-title class="" style="float: right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row> -->
        </div>
      </v-card>
    </div>
    <div v-if="userServices.csvExport">
      <vue-json-to-csv :json-data="this.payload">
        <v-btn elevation="2" class="mb-2" color="#BDBDBD">
          <b>{{ $t("button.exportToCSV") }}</b>
        </v-btn>
      </vue-json-to-csv>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService";
import HelperMethods from "@/utilities/HelperMethods";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data() {
    return {
      userServices: {},
      payload: [],
      devices: [],
      selectedDevices: [],
      headers: [
        {
          text: "Device",
          align: "start",
          sortable: "false",
          value: "name",
        },
        { text: "Temperature (ºC)", value: "temp" },
        { text: "CO2 (ppm)", value: "eco2" },
        { text: "Noise (dB)", value: "dbavg" },
        { text: "Humidity (%)", value: "humidity" },
        { text: "Light Intensity (lux)", value: "lux" },
        { text: "Air Quality (ppb)", value: "tvoc" },
        { text: "Pressure (hPa)", value: "pressure" },
        { text: "Wifi (dBm)", value: "rssi" },
        { text: "Date", value: "createdAt" },
      ],
      headers12: [
        {
          text: "Device",
          align: "start",
          sortable: "false",
          value: "name",
        },
        { text: "Temp (ºC)", value: "temp" },
        { text: "CO2 (ppm)", value: "eco2" },
        { text: "Noise (dB)", value: "dbavg" },
        { text: "Humidity (%)", value: "humidity" },
        { text: "Light Intensity (lux)", value: "lux" },
        { text: "Air Quality (ppb)", value: "tvoc" },
        { text: "Pressure (hPa)", value: "pressure" },
        { text: "Wifi (dBm)", value: "rssi" },
        { text: "Colorimetry (rgb)", value: "rgb" },
        { text: "Light Temperature (ºK)", value: "light_temp" },
        { text: "Nº of Devices", value: "devicesCount" },
        { text: "Devices Flow", value: "devicesFlow" },
        { text: "Date", value: "createdAt" },
      ],
      headersPt: [
        {
          text: "Dispositivo",
          align: "start",
          sortable: "false",
          value: "name",
        },
        { text: "Temperatura (ºC)", value: "temp" },
        { text: "CO2 (ppm)", value: "eco2" },
        { text: "Ruído (dB)", value: "dbavg" },
        { text: "Humidade (%)", value: "humidity" },
        { text: "Intensidade Luz (lux)", value: "lux" },
        { text: "Qualidade Ar (ppb)", value: "tvoc" },
        { text: "Pressão (hPa)", value: "pressure" },
        { text: "Wifi (dBm)", value: "rssi" },
        { text: "Data", value: "createdAt" },
      ],
      headersPt12: [
        {
          text: "Dispositivo",
          align: "start",
          sortable: "false",
          value: "name",
        },
        { text: "Temp. (ºC)", value: "temp" },
        { text: "CO2 (ppm)", value: "eco2" },
        { text: "Ruído (dB)", value: "dbavg" },
        { text: "Humidade (%)", value: "humidity" },
        { text: "Intensidade Luz (lux)", value: "lux" },
        { text: "Qual Ar (ppb)", value: "tvoc" },
        { text: "Pressão (hPa)", value: "pressure" },
        { text: "Wifi (dBm)", value: "rssi" },
        { text: "Colorimetria (rgb)", value: "rgb" },
        { text: "Temperatura Luz (ºK)", value: "light_temp" },
        { text: "Nº Dispo.", value: "devicesCount" },
        { text: "Fluxo Dispo.", value: "devicesFlow" },
        { text: "Data", value: "createdAt" },
      ],

      periodsToSelect: ["Daily", "Weekly", "Monthly"],
      periodsToSelectPt: ["Diário", "Semanal", "Mensal"],
      limitedPeriodsToSelect: ["Daily"],
      myloadingvariable: false,
      timePeriod: "",
      dateTo: "",
      dateFrom: "",
      name: "",
      installationLocal: "",
      search: "",
      error: null,
      success: null,

      // Calendar Variables
      dateRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      menu: false,
    };
  },
  components: {
    VueJsonToCsv,
  },
  async mounted() {
    this.userServices = this.$store.getters.userServices;
    console.log("userServices", this.userServices);
    this.devices = this.$store.getters.devices;

    this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
    this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");

    let temp = [];

    for (let i = 0; i < this.devices.length; i++) {
      temp.push(this.devices[i].id);
    }
    this.selectedDevices = temp;
    this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
  },

  methods: {
    updateTimePeriod(timePeriod) {
      this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
      switch (timePeriod) {
        case "Daily":
          this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
          break;
        case "Weekly":
          this.dateFrom = moment().subtract(1, "weeks").format("YYYY-MM-DD");
          break;
        case "Monthly":
          this.dateFrom = moment().subtract(1, "months").format("YYYY-MM-DD");
          break;
      }
      this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
    },

    async getPayload(devices, dateFrom, dateTo) {
      this.myloadingvariable = true;
      let self = this;
      try {
        const response = await DataService.getDataByInterval(
          devices,
          dateFrom,
          dateTo
        );

        this.payload = response.data;
        this.myloadingvariable = false;
      } catch (err) {
        console.log(err);
      }

      this.payload.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp3(value["createdAt"]);
        value.name = HelperMethods.nameDevice(
          value["refDeviceId"],
          self.devices
        );
        value.rgb = value.r + "/" + value.g + "/" + value.b;
      });
    },

    passDateRangeToDataTable() {
      if (this.dateRange.length === 1) {
        this.dateFrom = new Date(this.dateRange[0]);
        this.dateTo = new Date(Date.now());
        this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
        return;
      }

      let date1 = new Date(this.dateRange[0]);
      let date2 = new Date(this.dateRange[1]);

      if (date1 > date2) {
        this.dateFrom = date2;
        this.dateTo = date1;
        this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
        return;
      } else if (date2 > date1) {
        this.dateFrom = date1;
        this.dateTo = date2;
        this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
        return;
      } else {
        this.dateFrom = date1;
        this.dateTo = new Date(Date.now());
        this.getPayload(this.selectedDevices, this.dateFrom, this.dateTo);
        return;
      }
    },
  },
};
</script>

<style scoped></style>
